<template>
  <!-- eslint-disable -->
  <div v-if="!parseInt($store.state.user.uid)" class="page px-4 px-md-4 pt-4">
    <div class="d-flex px-0 px-md-4 pb-8 align-center justify-space-between">
      <span class="font-weight-bold">
        Finance
      </span>
      <span class="caption-small">
        Home > Finance
      </span>
    </div>
    <v-row no-gutters>
      <v-col cols="12" class="pb-4 px-4">
        <v-menu
          ref="menu"
          v-model="menu"
          :return-value.sync="dates"
          left
          offset-y
          :close-on-content-click="false"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="dateRangeText"
              label="Date Ranges"
              prepend-inner-icon="mdi-calendar-outline"
              readonly
              outlined
              dense
              hide-details
              clearable
              class="accent"
              v-bind="attrs"
              v-on="on"
              @click:clear="dates = []"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dates"
            no-title
            range
          >
            <v-spacer />
            <v-btn
              text
              class="text-capitalize"
              small
              @click="menu = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              class="text-capitalize"
              small
              @click="$refs.menu.save(dates)"
            >
              Ok
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" class="px-md-4">
        <v-row no-gutters class="d-flex flex-column-reverse flex-md-row">
          <v-col cols="12" class="col-md-8 pr-md-4">
            <v-data-table
              dense
              :headers="headers"
              :items="table"
              item-key="id"
              :server-items-length="tableTotal"
              :page.sync="tablePage"
              :items-per-page.sync="tableLimit"
              :options.sync="options"
              :footer-props="{
                itemsPerPageOptions: [1000]
              }"
              class="accent rounded-lg"
              :loading="tableLoading"
              loading-text="Loading... Please wait"
            >
              <template v-slot:item.selected_date="{ item }">
                <div class="caption">
                  {{ $localDT(item.selected_date, 'datelocal') }}
                </div>
              </template>
              <template v-slot:item.omzet="{ item }">
                <div class="caption">
                  {{ item.omzet | price }}
                </div>
              </template>
              <template v-slot:item.hpp="{ item }">
                <div class="caption">
                  {{ item.hpp | price }}
                </div>
              </template>
              <template v-slot:item.operations_items="{ item }">
                <div class="d-flex justify-space-between py-2 caption">
                  <div>
                    <span v-for="(e, iE) in CountOperations(item.operations_items, parseInt(item.summary_total)).data">
                      <v-chip x-small depressed class="mr-1 pl-2 grey darken-1 white--text" style="font-size: 8px;">
                        <v-icon x-small class="mr-1 red--text" @click.prevent="RemoveFinance(e.id)">
                          mdi-close
                        </v-icon>
                        {{ e.content }}
                      </v-chip>
                    </span>
                    <div class="font-weight-medium" style="font-size: 12px;">
                      Total : {{ CountOperations(item.operations_items, parseInt(item.summary_total)).total | price }}
                      <!-- <br>
                      -- : {{ item.operations_total | price }} -->
                    </div>
                  </div>
                  <v-btn x-small depressed color="grey lighten-2" light @click.prevent="newOperation.data.title = '', newOperation.data.amount = 0, newOperation.data.finance_date = item.selected_date, newOperation.show = true">
                    <v-icon x-small>
                      mdi-plus
                    </v-icon>
                  </v-btn>
                </div>
              </template>
            </v-data-table>
          </v-col>
          <v-col cols="12" class="col-md-4 pb-8 pb-md-4 px-2 px-md-4">
            <div class="accent pa-4 rounded-lg">
              <div class="pb-2 text-center body-2">
                Summary
              </div>
              <v-divider class="mb-4" />
              <div class="pb-4 text-left body-2">
                <div class="d-flex caption-small justify-space-between">
                  <span>
                    Omzet
                  </span>
                  <span>
                    {{ summary.omzet | price }}
                  </span>
                </div>
                <div class="d-flex caption-small justify-space-between">
                  <span>
                    Hpp
                  </span>
                  <span>
                    {{ summary.cogs | price }}
                  </span>
                </div>
                <v-divider class="mt-2 mb-1" />
                <div class="d-flex justify-end font-weight-bold caption-small">
                  <span class="mr-4">
                    Gross Profit
                  </span>
                  <span>
                    {{ (summary.omzet - summary.cogs) | price }}
                  </span>
                </div>
                <div class="d-flex caption-small justify-space-between mt-2">
                  <span>
                    Operations
                  </span>
                  <span>
                    {{ summary.operations | price }}
                  </span>
                </div>
                <v-divider class="mt-2 mb-1" />
                <div class="d-flex justify-end font-weight-bold caption-small">
                  <span class="mr-4">
                    Nett Profit
                  </span>
                  <span>
                    {{ (summary.omzet - summary.cogs - summary.operations) | price }}
                  </span>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-dialog
      v-model="newOperation.show"
      max-width="300"
      persistent
      scrollable
      light
    >
      <v-card>
        <v-card-title
          class="caption justify-start pl-4 py-3 subtitle-1"
        >
          New Operation : {{ $localDT(newOperation.data.finance_date, 'datelocal') }}
          <v-btn
            icon
            color="red"
            class="text-capitalize"
            small
            style="position: absolute;right: 10px;top: 10px;"
            @click="newOperation.show = false"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text class="px-4 pb-1">
          <div class="px-0 pt-4 pb-0">
            <v-text-field
              v-model="newOperation.data.title"
              :name="Math.random()"
              label="Title"
              autocomplete="disabled"
              outlined
              required
              dense
              hide-details
              class="mb-4 required"
            />
            <Currency
              v-model.number="newOperation.data.amount"
              label="Set Amount"
              prefix="Rp"
            />
          </div>
        </v-card-text>
        <v-divider />
        <v-card-actions class="justify-center px-4 pb-2">
          <v-btn
            depressed
            color="orange darken-4 px-4"
            small
            dark
            class="text-capitalize rounded-xl"
            @click="FINANCE_PROCESS()"
          >
            Create
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data: (vm) => ({
    uid: vm.$store.state.user.id,
    newOperation: {
      show: false,
      data: {
        title: '',
        user: vm.$store.state.user.id,
        finance_date: vm.$localDT('', 'datedefault'),
        amount: 0
      }
    },
    dates: [vm.$getThisMonth().from, vm.$getThisMonth().to],
    menu: false,
    search: '',
    tableLoading: true,
    table: [],
    tableTotal: 0,
    tablePage: 1,
    tableLimit: 1000,
    options: {},
    headers: [
      { text: 'Date', value: 'selected_date' },
      {
        text: 'Omzet',
        align: 'start',
        value: 'omzet'
      },
      {
        text: 'Hpp',
        align: 'start',
        value: 'hpp'
      },
      { text: 'Operations', value: 'operations_items', sortable: false }
    ],
    summary: {
      omzet: 0,
      hpp: 0,
      operations: 0
    }
  }),
  watch: {
    dates: function (fromTo) {
      if (fromTo) {
        if (fromTo.length === 2) {
          if ((new Date(fromTo[0])).getTime() < (new Date(fromTo[1])).getTime()) {
            this.FINANCE_GET('&from=' + fromTo[0] + '&to=' + fromTo[1])
            return
          } else {
            this.FINANCE_GET('&from=' + fromTo[1] + '&to=' + fromTo[0])
            return
          }
        }
      }
      this.FINANCE_GET()
    },
    options: {
      handler () {
        this.FINANCE_GET()
      },
      deep: true
    }
  },
  computed: {
    dateRangeText () {
      return this.dates.join(' ~ ')
    }
  },
  mounted () {
    this.FINANCE_GET()
  },
  methods: {
    FINANCE_GET (fromTo) {
      if (!fromTo) {
        if ((new Date(this.dates[0])).getTime() < (new Date(this.dates[1])).getTime()) {
          fromTo = '&from=' + this.dates[0] + '&to=' + this.dates[1]
        } else {
          fromTo = '&from=' + this.dates[1] + '&to=' + this.dates[0]
        }
      }
      this.tableLoading = true
      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      const params = '?v=1' + (parseInt(page) > 0 ? '&page=' + page : '') + (parseInt(itemsPerPage) > 0 ? '&limit=' + parseInt(itemsPerPage) : '') + (sortBy.length ? '&sortby=' + sortBy[0] + (sortDesc[0] ? '&sort=DESC' : '&sort=ASC') : '')
      this.$store.dispatch('FINANCE_GET', { uid: this.uid, q: params + (fromTo || '') }).then((res) => {
        if (res.status) {
          this.summary.omzet = 0
          this.summary.cogs = 0
          this.summary.operations = 0
          this.table = res.data.data.map((r) => {
            this.summary.omzet += parseInt(r.omzet) || 0
            this.summary.cogs += parseInt(r.hpp) || 0
            this.summary.operations += this.CountOperations(r.operations_items).total
            return r
          }) || []
          this.tableTotal = (Math.abs(this.$DateRangeDays(this.dates[0], this.dates[1])) + 1) || 0
        } else {
          this.table = []
          this.tableTotal = 0
        }
        this.tableLoading = false
      })
    },
    FINANCE_PROCESS () {
      const newOperation = Object.assign({}, this.newOperation.data)
      if (!newOperation.finance_date) {
        this.$store.commit('SNACKBAR', { show: true, text: 'Invalid Date' })
        return false
      }
      if (!newOperation.title) {
        this.$store.commit('SNACKBAR', { show: true, text: 'Please input title' })
        return false
      }
      if (!parseInt(newOperation.amount)) {
        this.$store.commit('SNACKBAR', { show: true, text: 'Please input amount' })
        return false
      }
      this.$store.dispatch('FINANCE_ADD', newOperation).then((res) => {
        if (res.status) {
          this.FINANCE_GET()
          this.newOperation.show = false
          this.newOperation.data.title = ''
          this.newOperation.data.amount = 0
        } else {
          this.$store.commit('SNACKBAR', { show: true, text: 'Something went wrong' })
        }
      })
    },
    RemoveFinance (id) {
      if (!confirm('Remove ?')) {
        return false
      }
      this.$store.dispatch('FINANCE_DELETE', {
        id: id,
        uid: this.uid
      }).then((res) => {
        if (res.status) {
          this.FINANCE_GET()
        } else {
          this.$store.commit('SNACKBAR', { show: true, text: 'Something went wrong' })
        }
      })
    },
    CountOperations (v) {
      const data = []
      let total = 0
      if (v) {
        const d = v.split(';;')
        for (let c = 0; c < d.length; c++) {
          const e = d[c].split('::')
          total += parseInt(e[1])
          data.push({ id: parseInt(e[0]), content: e[2] + ' (' + this.$price(e[1]) + ')' + ((c === d.length - 1) ? '' : ', ') })
        }
      }
      return {
        data,
        total
      }
    }
  }
}
</script>
